import React from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"

export default function DisclaimerBar1() {
  return (
    <section className="themes__panel-dark">
      <Container className="themes__panel-dark themes__flex-center-all pt-4">
        <ContentContainer2>
          <div>
            <BasicP2>© 2020 mrpaulxavier.com</BasicP2>
            <BasicP2>
              By visiting this page, you agree to our&nbsp;
              <a
                href="/terms-and-conditions"
                title="Terms and Conditions"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
              ,&nbsp;
              <a
                href="/earnings-disclaimer"
                title="Earnings Disclaimer"
                target="_blank"
                rel="noreferrer"
              >
                Earnings Disclaimer
              </a>
              , &&nbsp;
              <a
                href="/privacy-policy"
                title="Privacy Policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </BasicP2>
            <BasicP2>
              DISCLAIMER: The sales figures stated above are my personal sales
              figures. Please understand my results are not typical, I’m not
              implying you’ll duplicate them (or do anything for that matter). I
              have the benefit of practicing direct response marketing and
              advertising since 2013, and have an established following as a
              result. The average person who buys any “how to” information gets
              little to no results. I’m using these references for example
              purposes only. Your results will vary and depend on many factors
              …including but not limited to your background, experience, and
              work ethic. All business entails risk as well as massive and
              consistent effort and action. If you’re not willing to accept
              that, please DO NOT GET OUR INFORMATION
            </BasicP2>
            <BasicP2>
              In the interest of full disclosure… There will be an offer at the
              end of the training. This offer is simply for people who want to
              work more closely with me and my team on implementing what you
              learn in the training. It’s how we offer support to help you
              navigate through the small details and implement faster. This is
              NOT necessary. NOTHING will be held back in the training! You can
              use what you learn on your own WITHOUT buying anything… So if
              you’d like to see behind the scenes of a simple advertising
              campaign that has consistently brought in new clients month after
              month…
              <br />
              Then register above.
              <br />
              Seize the day,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Paul Xavier
            </BasicP2>
            <BasicP2>
              This site is not a part of the Facebook website or Facebook Inc.
              Additionally, This site is NOT endorsed by Facebook in any way.
              FACEBOOK is a trademark of FACEBOOK, Inc.
            </BasicP2>
          </div>
        </ContentContainer2>
      </Container>
    </section>
  )
}

const ContentContainer2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  width: 80%;
  padding-bottom: 20px;
`

const BasicP2 = styled.p`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: white;
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`
