import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import DisclaimerBar1 from "../main/DisclaimerBar1"

import { GiSmallFire } from "react-icons/gi"

import NlcLogo from "../../assets/images/nlc-logo-black-background.png"
import Left1 from "../../assets/images/case-study1/left1.png"
import Left2 from "../../assets/images/case-study1/left2.png"
import Left3 from "../../assets/images/case-study1/left3.png"
import Left4 from "../../assets/images/case-study1/left4.png"
import Left5 from "../../assets/images/case-study1/left5.png"
import Right1 from "../../assets/images/case-study1/right1.png"
import Right2 from "../../assets/images/case-study1/right2.png"
import Right3 from "../../assets/images/case-study1/right3.png"
import Right4 from "../../assets/images/case-study1/right4.png"
import Right5 from "../../assets/images/case-study1/right5.png"
import Right6 from "../../assets/images/case-study1/right6.png"
import Right7 from "../../assets/images/case-study1/right7.png"
import favicon from "../../assets/images/nlc-favicon.png"

export default function NlcFreeTraining1() {
  const handleStrategySessionClick = () => {
    window.location.href = "#NlcFreeTngScheduleStrategySession"
  }

  return (
    <>
      <Helmet
        title="Nexl Level Creators | Case Study"
        meta={[
          {
            name: "description",
            content:
              "The Best Online Courses For Learning Content Creation. Commit to daily growth by enrolling in immersive, online classes with the worlds best instructors. Unlock your creative potential.",
          },
          { name: "keywords", content: "...." },
        ]}
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      />
      <Header>
        <BasicImage1 src={NlcLogo} alt="Next Level Creators Logo" />
      </Header>
      <section className="themes__panel-light">
        <Container className="themes__panel-light">
          {/* <ContentContainer2> */}
          <Row>
            <BasicH1 style={{ margin: "25px 0" }}>
              How 112+ Content Creators Are Landing $24k+ Video Production
              Clients In 4 Simple Steps
            </BasicH1>
          </Row>
          <Row className="case-study__size-iframe-row">
            <iframe
              src="https://fast.wistia.net/embed/iframe/d7puaxlzdt?seo=false&videoFoam=true"
              title="How To Get $1k-10k Per Month Video Retainer Clients With Predictability"
              allow="autoplay; fullscreen"
              allowtransparency="true"
              frameBorder="0"
              scrolling="no"
              className="wistia_embed"
              name="wistia_embed"
              allowFullScreen
              msallowfullscreen="true"
              width="100%"
              height="100%"
            ></iframe>
          </Row>
          <Row className="justify-content-center">
            <BasicH3 style={{ fontWeight: "bold" }}>
              If you're serious & ready to add a minimum of $10,000 in Monthly
              Recurring Revenue (MRR) to your business in the next 90 days...
            </BasicH3>
          </Row>
          <Row>
            <ButtonContainer>
              <button
                className="btn btn-primary btn-large"
                onClick={handleStrategySessionClick}
              >
                Book A Free 1-on-1 Strategy Session
              </button>
            </ButtonContainer>
          </Row>
          {/* </ContentContainer2> */}
        </Container>
      </section>
      <section className="themes__panel-dark">
        <Container className="themes__flex-center-all">
          <FireIcon />
          <BasicH1
            className="themes__panel-dark"
            style={{ margin: "25px 0", color: "white" }}
          >
            Listen To Content Creators Who Have Implemented These 4 Systems To
            Reach $10k+ In Monthly Recurring Revenue (MRR)
          </BasicH1>
        </Container>
      </section>
      <section className="themes__panel-dark">
        <Container>
          <Row>
            <Col md={6} className="pb-3">
              <div className="iframe-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/0yBsBHEqJvo"
                  title="Testimnial 1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
            <Col md={6} className="pb-3">
              <div className="iframe-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/dbFaHwf6lnE"
                  title="Testimnial 2"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="p-3">
              <div className="iframe-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/S83y0IMXATs"
                  title="Testimnial 3"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
            <Col md={6} className="p-3">
              <div className="iframe-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/pTPD1DBFK2Q"
                  title="Testimnial 4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="p-3">
              <div className="iframe-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/8ipxx2GyVis"
                  title="Testimnial 5"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
            <Col md={6} className="p-3">
              <div className="iframe-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Yy8EkAXO3vA"
                  title="Testimnial 6"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="p-3">
              <div className="iframe-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/M8KRB5M9OAg"
                  title="Testimnial 7"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
            <Col md={6} className="p-3">
              <div className="iframe-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/F1Q_EDzLNQs"
                  title="Testimnial 8"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row>
            <Spacer2 className="themes__panel-dark" />
          </Row>
          <Row>
            <BasicH4
              className="themes__panel-dark"
              style={{ margin: "25px 0" }}
            >
              DISCLAIMER: The results shown on this page are NOT typical, they
              are from hard working filmmakers, video experts, content creators,
              cinematographers & directors who committed to implementing our
              strategies to achieve success.
            </BasicH4>
          </Row>
          <Row>
            <ButtonContainer>
              <button
                className="btn btn-primary btn-large"
                onClick={handleStrategySessionClick}
              >
                Book A Free 1-on-1 Strategy Session
              </button>
            </ButtonContainer>
          </Row>
        </Container>
      </section>
      <section className="themes__panel-light">
        <Container className="themes__panel-light themes__flex-center-all">
          <Spacer2 className="themes__panel-light" />
          <BasicH1 style={{ margin: "20px 0 25px 0" }}>
            The Sales Growth You Could Experience By Joining The Next Level
            Creators Community
          </BasicH1>
        </Container>
        <Container>
          <Row>
            <Col md={6}>
              <BasicImage2 src={Left1} alt="Proof" />
              <BasicImage2 src={Left2} alt="Proof" />
              <BasicImage2 src={Left3} alt="Proof" />
              <BasicImage2 src={Left4} alt="Proof" />
              <BasicImage2 src={Left5} alt="Proof" />
            </Col>
            <Col md={6} sm={4}>
              <BasicImage2 src={Right1} alt="Proof" />
              <BasicImage2 src={Right2} alt="Proof" />
              <BasicImage2 src={Right3} alt="Proof" />
              <BasicImage2 src={Right4} alt="Proof" />
              <BasicImage2 src={Right5} alt="Proof" />
              <BasicImage2 src={Right6} alt="Proof" />
              <BasicImage2 src={Right7} alt="Proof" />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <BasicH3>
              If you're serious & ready to add a minimum of $10,000 in Monthly
              Recurring Revenue (MRR) to your business in the next 90 days...
            </BasicH3>
          </Row>
          <Row>
            <Spacer2 />
          </Row>
          <Row>
            <ButtonContainer>
              <button
                className="btn btn-primary btn-large"
                onClick={handleStrategySessionClick}
              >
                Book A Free 1-on-1 Strategy Session
              </button>
            </ButtonContainer>
          </Row>
        </Container>
      </section>
      <section className="themes__panel-dark">
        <Container id="NlcFreeTngScheduleStrategySession">
          <Row>
            <BasicH1
              className="themes__panel-dark"
              style={{ margin: "50px 0 25px 0" }}
            >
              Schedule Your 1-on-1 Strategy Session
            </BasicH1>
            <BasicH4 className="themes__panel-dark">
              Receive in-depth guidance from some of the worlds most highly
              trained business advisors
            </BasicH4>
          </Row>
          <Row>
            <iframe
              title="Schedule Strategy Session"
              src="https://go.oncehub.com/pxrstrategysession?brdr=1pxd8d8d8&dt=&em=1&Si=1"
              id="SOI_pxrstrategysession"
              name="ScheduleOnceIframe"
              scrolling="yes"
              frameBorder="0"
              hspace="0"
              marginHeight="0"
              marginWidth="0"
              height="548px"
              width="100%"
              vspace="0"
              sotheme="light"
              style={{ margin: "35px 0 50px 0" }}
            ></iframe>
          </Row>
          <Row>
            <div style={{ textAlign: "center" }}>
              <BasicImage1
                src={NlcLogo}
                alt="Next Level Creators Logo"
                style={{ height: "75px" }}
              />
            </div>
          </Row>
        </Container>
      </section>
      <DisclaimerBar1 />
    </>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  height: 80px;
`
const BasicH1 = styled.h1`
  font-family: "Lato", "Open Sans";
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;

  color: rgb(68, 68, 68);
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 36px;
  }
`
const BasicH3 = styled.h3`
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  color: rgb(89, 89, 89);
  letter-spacing: normal;
  margin: 25px 0;
  width: 80%;
  line-height: 32px;
  text-align: center;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 25.6px;
  }
`
const BasicH4 = styled.h4`
  display: block;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  /* font-size: clamp(2rem, 3vw, 3rem); */
  text-align: center;
  font-weight: 900;
  color: black;
  margin: 0px;

  @media screen and (max-width: 420px) {
    /* font-size: 1.4rem; */
  }
`
const ButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`
const BasicImage1 = styled.img`
  height: 50px;
  margin: 20px;
`
const BasicImage2 = styled.img`
  display: block;
  max-width: 100%;
  box-shadow: 2px 2px 12px 2px #888890;
  margin-bottom: 25px;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`
const Spacer2 = styled.div`
  height: 15px;
`
const FireIcon = styled(GiSmallFire)`
  font-size: 5rem;
  color: goldenrod;
  margin-top: 25px;
`
