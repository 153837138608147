import * as React from "react"
import NlcFreeTraining from "../components/case-study1/NlcFreeTraining"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NlcFreeTrainingPage = () => (
  <Layout>
    <Seo title="About" />
    <NlcFreeTraining />
  </Layout>
)

export default NlcFreeTrainingPage
